import React from 'react'

const Teamtitle = () =>{
  return(
    <div className='flex flex-col gap-y-6'>
      <h1 className=' Mobile:text-[3rem] text-[4rem] font-medium'>Our Dynamic Team</h1>
      
      <p className=' Mobile:text-[1.5rem] text-[2rem] font-medium mb-10'>
        Our diverse team brings decades of combined experience and expertise in the real
         estate industry, ensuring personalized service and exceptional results for our clients.
      </p>
    </div>
  )
}

export default Teamtitle;